<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">考试管理</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Examination/TopicManage">题目管理 / </router-link>
          <span class="crumbs_item crumbs_last">新建题目</span>
        </div>
      </div>
    </div>
    <div class="all_content_box">
      <div class="boxDiv">
        <a-row>
          <a-col><span class="all_left_name all_required">题干</span></a-col>
        </a-row>
        <a-row >
          <a-col span="7">
            <a-textarea
              placeholder="请输入题干内容"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <a-row>
        <a-col><span class="all_left_name all_required">题目类型<i>注：若选择单选，在选项中仅可选择一个正确答案；若选择多选，可选择多个正确答案</i></span></a-col>
      </a-row>
      <a-row class="boxDiv">
        <a-col type="flex" span="8">
          <a-radio-group name="radioGroup" class="radio">
            <a-radio :value="1">单选</a-radio>
            <a-radio :value="2">多选</a-radio>
            <a-radio :value="3">判断题</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row>
        <a-col><span class="all_left_name all_required">题目难度</span></a-col>
      </a-row>
      <a-row class="boxDiv">
        <a-col type="flex" span="8">
          <a-radio-group name="radioGroup" class="radio">
            <a-radio :value="1">简单</a-radio>
            <a-radio :value="2">一般</a-radio>
            <a-radio :value="3">困难</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <!-- 选项 -->
      <div class="option-box boxDiv">
        <a-row>
          <a-col><span class="all_left_name all_required">选项</span></a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-input placeholder="请输入选项内容" /><a-checkbox>正确</a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-input placeholder="请输入选项内容" /><a-checkbox>正确</a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-input placeholder="请输入选项内容" /><a-checkbox>正确</a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-input placeholder="请输入选项内容" /><a-checkbox>正确</a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-button class="all_boder_btn">+添加选项</a-button>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="7" class="option">
            <a-radio>可打乱选项顺序</a-radio>
          </a-col>
        </a-row>
      </div>
      <div class="boxDiv">
        <a-row>
          <a-col><span class="all_left_name all_required">关联章节</span></a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-select style="width: 300px" placeholder="请选择关联章节">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="1">
                未命名
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
    </div>
    <div>
      <a-button type="primary" style="margin-right:20px" class="btn">确认</a-button>
      <a-button class="all_boder_btn">取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.all_left_name{
  i{
    margin-left: 20px;
    color: #FF3838;
    font-style: normal;
  }
}
.radio{
  width: 400px;
  display: flex;
  justify-content: space-between;
}
.boxDiv{
  margin-bottom: 20px;
}
.option-box{
  .option{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    /deep/ .ant-input{
      flex: 1;
      margin-right: 20px;
    }
  }
}
</style>
